import React, { useState } from 'react';
import { Link } from 'gatsby-plugin-intl';
import classnames from 'classnames';

import TextLink from '../TextLink';
import Language from '../Language';
import Authorization from '../Authorization';

import menu from '../../img/icons/menu.svg';
import close from '../../img/icons/close.svg';
import logo from '../../img/logo.png';

import style from './Header.module.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const navClassNames = classnames(style.nav, {
    [style.open]: menuOpen,
  });

  return (
    <header className={style.header}>
      <div className={style.container}>
        {/* eslint-disable-next-line */}
        <div className={style.menuButton} onClick={() => setMenuOpen(true)}>
          <img src={menu} alt="menu" />
        </div>
        <Link to="/" className={style.logoContainer}>
          <img src={logo} alt="distry.io" />
        </Link>
        <nav className={navClassNames}>
          {/* eslint-disable-next-line */}
          <div className={style.closeButton} onClick={() => setMenuOpen(false)}>
            <img src={close} alt="close" />
          </div>
          <TextLink
            className={style.navItem}
            linkTo="/cities/"
            titleKey="main.header.links.city"
          />
          <TextLink
            className={style.navItem}
            linkTo="/contact/"
            titleKey="main.header.links.contact"
          />
          <Language className={style.navItem} />
          <Authorization className={style.navItem} />
        </nav>
      </div>
    </header>
  );
};

export default Header;
