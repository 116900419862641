import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './Skeleton.module.css';

const Skeleton = ({ className }) => (
  <div className={classnames(style.skeleton, className)} />
);

Skeleton.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Skeleton;
