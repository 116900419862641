import { SET_USER_MODAL_IS_OPEN, SET_FILTERS_MODAL_IS_OPEN } from './constants';

export const setUserModalIsOpen = (payload) => ({
  type: SET_USER_MODAL_IS_OPEN,
  payload,
});

export const setFilersModalIsOpen = (payload) => ({
  type: SET_FILTERS_MODAL_IS_OPEN,
  payload,
});
