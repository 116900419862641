import {
  SET_HOOD_LIST_SORTING_TYPE,
  SET_RATING_FILTERS,
  TOGGLE_ALL_RATING_FILTERS,
  SET_HOOD_LIST_SEARCH_QUERY,
  SET_LOG_IN_START,
  SET_LOG_OUT_START,
  TOGGLE_IS_MOBILE_MAP_HIDDEN,
} from './constants';

import { getRatingFilters } from '../../utils/rating-helper';

export const setHoodListSortingType = (payload) => ({
  type: SET_HOOD_LIST_SORTING_TYPE,
  payload,
});

export const setHoodListSearchQuery = (payload) => ({
  type: SET_HOOD_LIST_SEARCH_QUERY,
  payload,
});

export const setRatingFilters = ({ type, checked }) => (dispatch, getStore) => {
  const {
    settingsReducer: { ratingFilters },
  } = getStore();

  return dispatch({
    type: SET_RATING_FILTERS,
    payload: {
      type,
      checked,
      ratingFilters: {
        ...ratingFilters,
        [type]: checked,
      },
    },
  });
};

export const toggleAllRatingFilters = (selectAll) => ({
  type: TOGGLE_ALL_RATING_FILTERS,
  payload: {
    ratingFilters: getRatingFilters(selectAll),
  },
});

export const setLogInStart = (payload) => ({
  type: SET_LOG_IN_START,
  payload,
});

export const setLogOutStart = (payload) => ({
  type: SET_LOG_OUT_START,
  payload,
});

export const toggleIsMobileMapHidden = () => ({
  type: TOGGLE_IS_MOBILE_MAP_HIDDEN,
});
