import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { IntlContextConsumer, changeLocale, useIntl } from 'gatsby-plugin-intl';

import Dropdown from '../Dropdown';

import style from './Language.module.css';

const Language = ({ className }) => {
  const intl = useIntl();

  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => {
        const options = languages.map((value) => ({
          value,
          label: intl.formatMessage({ id: `main.header.languages.${value}` }),
        }));

        return (
          <Dropdown
            containerClassName={classnames(style.container, className)}
            optionsClassName={classnames(style.options, className)}
            options={options}
            defaultLabel={intl.formatMessage({
              id: `main.header.languages.${currentLocale}`,
            })}
            defaultValue={currentLocale}
            onChange={changeLocale}
          />
        );
      }}
    </IntlContextConsumer>
  );
};

Language.propTypes = {
  className: PropTypes.string,
};

export default Language;
