import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import ReactTooltip from 'react-tooltip';

import CheckBox from '../CheckBox';

import { iconsMap } from '../../utils/rating-helper';

import style from './RatingFilter.module.css';

const RatingFilter = ({ type, checked, onCheck }) => {
  const intl = useIntl();

  return (
    <div className={style.container}>
      <div
        className={style.title}
        data-tip={intl.formatMessage({
          id: `main.rating.${type}.description`,
        })}
        data-for={type}
      >
        <img className={style.icon} src={iconsMap[type]} alt={type} />
        <FormattedMessage id={`main.rating.${type}.title`} />
        <ReactTooltip
          id={type}
          backgroundColor="var(--color-dark-blue)"
          place={'bottom'}
          effect={'solid'}
          className={style.tip}
        />
      </div>
      <CheckBox checked={checked} onCheck={onCheck} />
    </div>
  );
};

RatingFilter.propTypes = {
  type: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
};

export default RatingFilter;
