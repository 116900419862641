import React, { useCallback } from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';

import RatingFilter from '../RatingFilter';
import TextButton from '../TextButton';
import Modal from '../Modal';

import { setFilersModalIsOpen } from '../../state/modals/actions';
import {
  setRatingFilters,
  toggleAllRatingFilters,
} from '../../state/settings/actions';

import { ratingFields } from '../../utils/rating-helper';

import style from './FiltersModal.module.css';

const mapStateToPropsSelector = createSelector(
  (state) => state.modalsReducer,
  (state) => state.settingsReducer,
  ({ filtersModalIsOpen }, { ratingFilters }) => ({
    filtersModalIsOpen,
    ratingFilters,
    isSelectAllAvailable: !Object.keys(ratingFilters).every(
      (key) => ratingFilters[key]
    ),
  })
);

const FiltersModal = () => {
  const dispatch = useDispatch();

  const {
    filtersModalIsOpen,
    ratingFilters,
    isSelectAllAvailable,
  } = useSelector(mapStateToPropsSelector);

  const onCheck = useCallback(
    (type, checked) => dispatch(setRatingFilters({ type, checked })),
    [dispatch]
  );

  const closeFilersModal = useCallback(
    () => dispatch(setFilersModalIsOpen(false)),
    [dispatch]
  );

  const toggleAllFilters = useCallback(
    () => dispatch(toggleAllRatingFilters(isSelectAllAvailable)),
    [dispatch, isSelectAllAvailable]
  );

  return (
    <Modal
      isOpen={filtersModalIsOpen}
      titleKey="main.filtersModal.title"
      onClose={closeFilersModal}
    >
      <p className={style.description}>
        <FormattedMessage id="main.filtersModal.description" />
      </p>
      {ratingFields.map((type) => (
        <RatingFilter
          type={type}
          key={type}
          onCheck={() => onCheck(type, !ratingFilters[type])}
          checked={ratingFilters[type]}
        />
      ))}
      <div className={style.buttonContainer}>
        <TextButton
          onClick={toggleAllFilters}
          titleKey={`main.filtersModal.${
            isSelectAllAvailable ? 'selectAll' : 'deselectAll'
          }`}
        />
      </div>
    </Modal>
  );
};

export default FiltersModal;
