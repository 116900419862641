import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import classnames from 'classnames';

import style from './TextButton.module.css';

const TextButton = ({ onClick, titleKey, title, values, className }) => (
  // eslint-disable-next-line
  <span className={classnames(style.button, className)} onClick={onClick}>
    {titleKey ? <FormattedHTMLMessage id={titleKey} values={values} /> : title}
  </span>
);

TextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  titleKey: PropTypes.string,
  className: PropTypes.string,
  values: PropTypes.object,
};

export default TextButton;
