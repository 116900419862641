import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Header from '../Header';
import SEO from '../SEO';
import Footer from '../Footer';
import FiltersModal from '../FiltersModal';
import UserModal from '../UserModal';

import style from './Layout.module.css';

const Layout = ({ children, pageTitle, pageImage, slug, map = false }) => {
  const classes = classNames(style.content, {
    [style.map]: map,
  });

  return (
    <div className={style.container}>
      <SEO pageTitle={pageTitle} pageImage={pageImage} slug={slug} />
      <Header />
      <div className={classes}>
        <FiltersModal />
        <UserModal />
        {children}
      </div>
      {!map && <Footer />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  pageImage: PropTypes.string,
  slug: PropTypes.string,
};

export default Layout;
