import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useIntl } from 'gatsby-plugin-intl';

import Modal from '../Modal';
import TextButton from '../TextButton';
import UserTitle from '../UserTitle';

import { setUserModalIsOpen } from '../../state/modals/actions';
import { setLogOutStart } from '../../state/settings/actions';
import { useAuth0 } from '../../utils/auth';

import style from './UserModal.module.css';

const mapStateToPropsSelector = createSelector(
  (state) => state.modalsReducer,
  ({ userModalIsOpen }) => userModalIsOpen
);

const UserModal = () => {
  const dispatch = useDispatch();
  const { logout, user } = useAuth0();

  const intl = useIntl();

  const userModalIsOpen = useSelector(mapStateToPropsSelector);

  const closeUserModal = useCallback(
    () => dispatch(setUserModalIsOpen(false)),
    [dispatch]
  );

  const onLogOut = useCallback(() => {
    dispatch(setLogOutStart({ redirectTo: window.location.pathname }));

    logout({
      returnTo: `${window.location.origin}/${intl.locale}/logout/`,
    });
  }, [logout, dispatch, intl.locale]);

  return (
    <Modal
      onClose={closeUserModal}
      isOpen={userModalIsOpen}
      titleKey="main.userModal.title"
    >
      {user && (
        <UserTitle {...user} className={style.userTitle} showEmail={true} />
      )}
      <TextButton
        className={style.action}
        onClick={onLogOut}
        titleKey="main.userModal.logOut"
      />
    </Modal>
  );
};

export default UserModal;
