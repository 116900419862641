import React from 'react';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import TextLink from '../TextLink';

import facebookIcon from '../../img/icons/facebook.svg';

import style from './Footer.module.css';

const Footer = () => (
  <footer className={style.footer}>
    <TextLink
      className={style.item}
      linkTo={'/privacy-policy/'}
      titleKey="main.footer.links.privacy"
    />
    <TextLink
      className={style.item}
      linkTo={'/terms-of-service/'}
      titleKey="main.footer.links.terms"
    />
    <a
      href="https://www.facebook.com/distry.io/"
      target="_blank"
      rel="noreferrer"
      className={style.socialIcon}
    >
      <img src={facebookIcon} alt="Facebook" />
    </a>
    <span className={style.item}>
      <FormattedHTMLMessage id="main.footer.copyright" />
    </span>
  </footer>
);

export default Footer;
