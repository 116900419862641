import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';

import style from './UserBadge.module.css';
import ReactTooltip from 'react-tooltip';

const UserBadge = ({ reviewsAmount, cityTitle }) => {
  const intl = useIntl();

  if (reviewsAmount < 5) {
    return null;
  }

  return (
    <>
      <div
        className={style.container}
        data-tip={intl.formatMessage(
          {
            id: `main.userBadge.expert.tip`,
          },
          {
            reviewsAmount,
            cityTitle,
          }
        )}
        data-for="badge"
      >
        <FormattedMessage id={`main.userBadge.expert.title`} />
      </div>
      <ReactTooltip
        id="badge"
        backgroundColor="var(--color-dark-blue)"
        place={'bottom'}
        effect={'solid'}
        className={style.tip}
      />
    </>
  );
};

UserBadge.propTypes = {
  reviewsAmount: PropTypes.number.isRequired,
  cityTitle: PropTypes.string.isRequired,
};

export default UserBadge;
