import React from 'react';
import PropTypes from 'prop-types';
import { Link, FormattedHTMLMessage } from 'gatsby-plugin-intl';
import classnames from 'classnames';

import style from './TextLink.module.css';

const TextLink = ({ linkTo, titleKey, title, className, values = {} }) => (
  <Link className={classnames(style.link, className)} to={linkTo}>
    {titleKey ? <FormattedHTMLMessage id={titleKey} values={values} /> : title}
  </Link>
);

TextLink.propTypes = {
  linkTo: PropTypes.string.isRequired,
  title: PropTypes.string,
  values: PropTypes.object,
  titleKey: PropTypes.string,
  className: PropTypes.string,
};

export default TextLink;
