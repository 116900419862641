import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { format } from 'timeago.js';
import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import UserBadge from '../UserBadge';

import style from './UserTitle.module.css';

const UserTitle = ({
  picture,
  name,
  reviewsByCityAmount,
  cityTitle,
  email,
  showEmail,
  createdAt,
  className,
}) => {
  const intl = useIntl();

  const currentCityReviewsAmount =
    cityTitle && reviewsByCityAmount && reviewsByCityAmount[cityTitle];
  const totalReviewsAmount =
    reviewsByCityAmount &&
    Object.keys(reviewsByCityAmount).reduce(
      (acc, key) => acc + reviewsByCityAmount[key],
      0
    );

  return (
    <div className={classnames(style.container, className)}>
      <div className={style.pictureContainer}>
        <img src={picture} className={style.picture} alt="user" />
      </div>
      <div className={style.content}>
        <div className={style.title}>
          <b>{name}</b>
          {currentCityReviewsAmount && (
            <UserBadge
              reviewsAmount={currentCityReviewsAmount}
              cityTitle={cityTitle}
            />
          )}
        </div>
        {totalReviewsAmount && (
          <div className={style.subTitle}>
            <FormattedHTMLMessage
              id="main.userTitle.totalReviewsAmount"
              values={{ totalReviewsAmount }}
            />
          </div>
        )}
        {showEmail && email && (
          <div className={style.subTitle}>
            <FormattedHTMLMessage
              id="main.userTitle.email"
              values={{ email }}
            />
          </div>
        )}
        {createdAt && (
          <div className={style.subTitle}>{format(createdAt, intl.locale)}</div>
        )}
      </div>
    </div>
  );
};

UserTitle.propTypes = {
  picture: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  reviewsByCityAmount: PropTypes.object,
  createdAt: PropTypes.number,
  email: PropTypes.string,
  cityTitle: PropTypes.string,
  className: PropTypes.string,
  showEmail: PropTypes.bool,
};

export default UserTitle;
