import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { useDispatch } from 'react-redux';

import TextButton from '../TextButton';
import Skeleton from '../Skeleton';

import { setUserModalIsOpen } from '../../state/modals/actions';
import { setLogInStart } from '../../state/settings/actions';
import { useAuth0 } from '../../utils/auth';

import style from './Authorization.module.css';

const Authorization = ({ className }) => {
  const { isAuthenticated, loginWithRedirect, loading, user } = useAuth0();
  const intl = useIntl();
  const dispatch = useDispatch();

  const containerClassNames = classnames(style.container, className);

  const openUserModal = useCallback(() => dispatch(setUserModalIsOpen(true)), [
    dispatch,
  ]);
  const onLogin = useCallback(() => {
    dispatch(setLogInStart({ redirectTo: window.location.pathname }));

    loginWithRedirect({
      redirect_uri: `${window.location.origin}/${intl.locale}/login/`,
    });
  }, [loginWithRedirect, intl.locale, dispatch]);

  if (loading) {
    return <Skeleton className={style.picture} />;
  }

  return !isAuthenticated ? (
    <TextButton
      className={classnames(containerClassNames, style.login)}
      onClick={onLogin}
      titleKey="main.header.links.login"
    />
  ) : (
    // eslint-disable-next-line
    <div className={containerClassNames} onClick={openUserModal}>
      <img src={user.picture} alt="user" className={style.picture} />
    </div>
  );
};

Authorization.propTypes = {
  className: PropTypes.string,
};

export default Authorization;
