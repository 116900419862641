import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './CheckBox.module.css';

const CheckBox = ({ checked, onCheck }) => {
  const classNames = classnames(style.container, {
    [style.checked]: checked,
  });

  return (
    // eslint-disable-next-line
    <div className={classNames} onClick={onCheck}>
      <div className={style.checkMark} />
    </div>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
};

export default CheckBox;
