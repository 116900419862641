import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './Dropdown.module.css';

const Dropdown = ({
  defaultLabel,
  defaultValue,
  options,
  onChange,
  containerClassName,
  optionsClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const close = (event) => {
    if (isOpen && ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', close, false);
    document.addEventListener('touchend', close, false);

    return () => {
      document.removeEventListener('click', close, false);
      document.removeEventListener('touchend', close, false);
    };
  });

  useEffect(() => {
    setIsOpen(false);
  }, [defaultValue]);

  const containerClassNames = classnames(style.container, containerClassName, {
    [style.open]: isOpen,
  });
  const optionsClassNames = classnames(style.options, optionsClassName);
  const currentValueClassNames = classnames(
    style.optionWrapper,
    style.currentValue
  );

  const onOptionClick = (value) => {
    if (value === defaultValue) {
      setIsOpen(false);
      return;
    }

    onChange(value);
  };

  return (
    <div className={containerClassNames} ref={ref}>
      {/* eslint-disable-next-line */}
      <div
        className={currentValueClassNames}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={style.option}>{defaultLabel}</span>
        <span className={style.arrow} />
      </div>
      <div className={optionsClassNames}>
        {options.map(({ value, label }) => (
          // eslint-disable-next-line
          <div
            className={style.optionWrapper}
            onClick={() => onOptionClick(value)}
            key={value}
          >
            <span className={style.option}>{label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  defaultLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
};

export default Dropdown;
