import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import classnames from 'classnames';

import close from '../../img/icons/close.svg';

import style from './Modal.module.css';

const Modal = ({ isOpen, titleKey, onClose, children }) => {
  const containerClassNames = classnames(style.container, {
    [style.open]: isOpen,
  });

  const onModalClick = (event) => {
    event.stopPropagation();
  };

  return (
    // eslint-disable-next-line
    <div className={containerClassNames} onClick={onClose}>
      {/* eslint-disable-next-line */}
      <div className={style.modal} onClick={onModalClick}>
        <div className={style.title}>
          <FormattedHTMLMessage id={titleKey} />
          {/* eslint-disable-next-line */}
          <div className={style.closeButton} onClick={onClose}>
            <img src={close} alt="close" />
          </div>
        </div>
        <div className={style.content}>{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  titleKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
